import React from 'react';
import MuiBox from '../../reusableComponents/MuiBox';
import MuiTypography from '../../reusableComponents/MuiTypography';
import { greyColorText, redColor, yellowColor } from '../../helper/colors';
import { Hidden } from '@mui/material';
import mainOffice from '../../assets/ucionica/mainOffice.webp';
import { Helmet } from 'react-helmet';

const ContactUs = () => {
  return (
    <MuiBox sx={{ padding: '0 0.5rem' }}>
      <Helmet>
        <title>Kontakt - Auto škola DUŠAN START</title>
        <meta charSet='utf-8' />
        <meta
          name='description'
          content='Kontaktirajte Auto školu DUŠAN START u Smederevu za sve informacije o vozačkim obukama, uključujući brojeve telefona, adresu, lokaciju na Google mapi i ostale podatke.'
        />
        <meta
          name='keywords'
          content='auto škola, Dušan Start, Smederevo, kontakt, telefon, lokacija, adresa, Google map, vozačka obuka, vozačka škola'
        />
        <meta
          name='author'
          content='Marko Stojanovic'
        />
        <link
          rel='canonical'
          href='https://www.dusanstartautoskola.rs/contact'
        />

        <meta
          property='og:title'
          content='Kontakt - Auto škola DUŠAN START'
        />
        <meta
          property='og:description'
          content='Kontaktirajte Auto školu DUŠAN START u Smederevu za sve informacije o vozačkim obukama. Pronađite brojeve telefona, adresu i lokaciju na Google mapi.'
        />
        <meta
          property='og:image'
          content='https://www.dusanstartautoskola.rs/logo512.png' // Uključiti stvarni URL
        />
        <meta
          property='og:url'
          content='https://www.dusanstartautoskola.rs/contact'
        />
        <meta
          property='og:type'
          content='website'
        />
        <meta
          property='og:site_name'
          content='Auto škola DUŠAN START'
        />
        <meta
          property='og:locale'
          content='sr_RS'
        />

        <meta
          name='twitter:card'
          content='summary_large_image'
        />
        <meta
          name='twitter:title'
          content='Kontakt - Auto škola DUŠAN START'
        />
        <meta
          name='twitter:description'
          content='Kontaktirajte Auto školu DUŠAN START u Smederevu za sve informacije o vozačkim obukama. Pronađite brojeve telefona, adresu i lokaciju na Google mapi.'
        />
        <meta
          name='twitter:image'
          content='https://www.dusanstartautoskola.rs/logo512.png' // Uključiti stvarni URL
        />
        <meta
          name='twitter:site'
          content='@twitterhandle'
        />
        <meta
          name='twitter:creator'
          content='@twitterhandle'
        />
      </Helmet>

      <MuiBox
        sx={{
          backgroundImage: `url(${mainOffice})`,
          backgroundSize: 'cover',
          height: { xs: '400px', sm: '500px', md: '600px', lg: '600px' },
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}>
        <MuiTypography
          text='AUTO ŠKOLA DUŠAN START'
          sx={{
            color: '#fff',
            fontSize: {
              xs: '1.3rem',
              sm: '1.5rem',
              md: '1.5rem',
              lg: '1.8rem',
            },
            textAlign: 'center',
            marginBottom: '1.5rem',
            fontWeight: 'bold',
            textShadow: '2px 2px 5px rgba(0, 0, 0, 0.7)',
            WebkitTextStroke: '1px black',
          }}
        />
        <MuiTypography
          text='Kontaktirajte nas'
          sx={{
            color: '#fff',
            fontSize: {
              xs: '2rem',
              sm: '2.8rem',
              md: '3.2rem',
              lg: '3.5rem',
            },
            textAlign: 'center',
            marginBottom: '1.5rem',
            textShadow: '2px 2px 5px rgba(0, 0, 0, 0.7)',
            WebkitTextStroke: '1px black',
            fontWeight: 'bold',
          }}
        />
        <MuiTypography
          text='Profesionalni instruktori sa višegodišnjim iskustvom u obuci'
          sx={{
            color: '#fff',
            fontSize: {
              xs: '1.3rem',
              sm: '1.5rem',
              md: '1.5rem',
              lg: '1.8rem',
            },
            textAlign: 'center',
            marginBottom: '1.5rem',
            textShadow: '2px 2px 5px rgba(0, 0, 0, 0.7)',
            WebkitTextStroke: '1px black',
            fontWeight: 'bold',
          }}
        />
      </MuiBox>
      <MuiBox sx={{ padding: '2rem 1rem' }}>
        <MuiTypography
          text='AUTO ŠKOLA DUŠAN START'
          sx={{
            color: yellowColor,
            fontSize: {
              xs: '1.5rem',
              sm: '1.9rem',
              md: '2.5rem',
              lg: '3rem',
            },
            textAlign: 'center',
            marginBottom: '1.5rem',
            fontWeight: 'bold',
          }}
        />
        <MuiTypography
          text='Adresa:'
          sx={{
            color: greyColorText,
            fontSize: {
              xs: '1.3rem',
              sm: '1.5rem',
              md: '1.5rem',
              lg: '1.8rem',
            },
            textAlign: 'left',
            marginBottom: '1rem',
          }}
        />
        <MuiTypography
          text='Miloša Velikog 15, Smederevo 11300'
          sx={{
            color: '#000',
            fontSize: {
              xs: '1.3rem',
              sm: '1.5rem',
              md: '1.5rem',
              lg: '1.8rem',
            },
            textAlign: 'left',
            marginBottom: '1rem',
          }}
        />
        <MuiTypography
          text='Broj kancelarije:'
          sx={{
            color: greyColorText,
            fontSize: {
              xs: '1.3rem',
              sm: '1.5rem',
              md: '1.5rem',
              lg: '1.8rem',
            },
            marginBottom: '0.3rem',
          }}
        />
        <Hidden smUp>
          <p
            style={{
              color: redColor,
              fontSize: '1.1rem',
              marginBottom: '1rem',
            }}>
            <a
              href={`tel:+381616872152`}
              style={{ color: redColor }}>
              +381 64 94-45-346
            </a>
          </p>
        </Hidden>
        <Hidden smDown>
          <MuiTypography
            text='+381 64 94-45-346'
            sx={{
              color: redColor,
              fontSize: '1.5rem',
              marginBottom: '1rem',
            }}
          />
        </Hidden>

        <MuiTypography
          text='Direktor škole:'
          sx={{
            color: greyColorText,
            fontSize: {
              xs: '1.3rem',
              sm: '1.5rem',
              md: '1.5rem',
              lg: '1.8rem',
            },
            marginBottom: '0.3rem',
          }}
        />
        <Hidden smDown>
          <MuiTypography
            text='+381 66 66-225-333'
            sx={{
              color: redColor,
              fontSize: '1.5rem',
              marginBottom: '1rem',
            }}
          />
        </Hidden>
        <Hidden smUp>
          <p
            style={{
              color: redColor,
              fontSize: '1.1rem',
              marginBottom: '1rem',
            }}>
            <a
              href={`tel:+381616872152`}
              style={{ color: redColor }}>
              +381 66 66-225-333
            </a>
          </p>
        </Hidden>

        <MuiTypography
          text='Kontakt Informacije:'
          sx={{
            color: greyColorText,
            fontSize: {
              xs: '1.3rem',
              sm: '1.5rem',
              md: '1.5rem',
              lg: '1.8rem',
            },
            marginBottom: '0.3rem',
          }}
        />
        <Hidden smDown>
          <MuiTypography
            text='+381 64 36-20-725'
            sx={{
              color: redColor,
              fontSize: '1.5rem',
              marginBottom: '1rem',
            }}
          />
        </Hidden>
        <Hidden smUp>
          <p
            style={{
              color: redColor,
              fontSize: '1.1rem',
              marginBottom: '1rem',
            }}>
            <a
              href={`tel:+381616872152`}
              style={{ color: redColor }}>
              +381 64 36-20-725
            </a>
          </p>
        </Hidden>
        <MuiTypography
          text='Email:'
          sx={{
            color: greyColorText,
            fontSize: {
              xs: '1.3rem',
              sm: '1.5rem',
              md: '1.5rem',
              lg: '1.8rem',
            },
            textAlign: 'left',
            marginBottom: '0.5rem',
          }}
        />
        <MuiTypography
          text='petkoarsic.sd@gmail.com'
          href='mailto:email@example.com'
          component='a'
          sx={{
            color: yellowColor,
            fontSize: {
              xs: '1.3rem',
              sm: '1.5rem',
              md: '1.5rem',
              lg: '1.8rem',
            },
            textAlign: 'left',
            marginBottom: '1.5rem',
            display: 'inline-block',
          }}
        />
        <MuiTypography
          text='Radno vreme:'
          sx={{
            color: greyColorText,
            fontSize: {
              xs: '1.3rem',
              sm: '1.5rem',
              md: '1.5rem',
              lg: '1.8rem',
            },
            textAlign: 'left',
            marginBottom: '1rem',
          }}
        />
        <MuiTypography
          text='Radnim danima: 09:00 - 17:00'
          sx={{
            color: yellowColor,
            fontSize: {
              xs: '1.3rem',
              sm: '1.5rem',
              md: '1.5rem',
              lg: '1.8rem',
            },
            textAlign: 'left',
            marginBottom: '0.5rem',
          }}
        />
        <MuiTypography
          text='Subotom: 09:00 - 12:00'
          sx={{
            color: yellowColor,
            fontSize: {
              xs: '1.3rem',
              sm: '1.5rem',
              md: '1.5rem',
              lg: '1.8rem',
            },
            textAlign: 'left',
          }}
        />
      </MuiBox>
      <MuiBox>
        <iframe
          title='skola'
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2837.7027971026464!2d20.92772087659645!3d44.66442348644394!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4750935e0f794dbf%3A0xbe1a09c56c37c151!2sAuto%20%C5%A1kola%20DU%C5%A0AN%20START!5e0!3m2!1ssr!2srs!4v1714999031493!5m2!1ssr!2srs'
          width='600'
          height='450'
          style={{ width: '100%', borderRadius: '0.5rem' }}
          allowFullScreen=''
          loading='lazy'
          referrerPolicy='no-referrer-when-downgrade'></iframe>
      </MuiBox>
    </MuiBox>
  );
};

export default ContactUs;
